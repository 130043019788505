import img1 from '../images/post/imgpost11.jpg'
import img2 from '../images/post/imgpost13.jpg'
import img3 from '../images/post/imgpost12.jpg'

const dataBlog = [
    {
        id: 1,
        img : img1,
        category: '1',
        title: 'NFT Guide: Why They Matter For Music?',       
    },  
    
    
    
]

export default dataBlog;