const menus = [
    {
        id: 1,
        name: 'Inicio',
        links: '/',
       
    },
    {
        id: 2,
        name: 'Actividades/Pruebas',
        links: '/actividades-pruebas',
       
    },
    {
        id: 3,
        name: 'Sanciones',
        links: '/sanciones',
      
    },
    {
        id: 5,
        name: 'Galería',
        links: '/galeria',
       
    },
  
    
]

export default menus;