import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
// import { Modal } from "react-bootstrap";
import "./styles.scss";

function SliderItem(props) {
  const { item } = props;

  const [isOpen, setOpen] = useState(false);
  return (
    <div className="box-slider">
      <div className="box-slider__main">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-12">
              <div className="content-box">
                <h2 className="title">{item.title}</h2>
                <h2 className="subtitle">{item.subtille}</h2>
                <img className="logos" src={item.logos} alt="SAG" />
                <div className="wrap-btn"></div>
              </div>
            </div>
            <div className="col-xl-8 col-md-12">
              <div className="image">
                <img src={item.img} alt="SAG" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderItem;
