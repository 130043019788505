import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import AboutOne from "./AboutOne";
import AboutTwo from "./AboutTwo";
import ComingSoon from "./ComingSoon";
import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
import RoadMapTwo from "./RoadMapTwo";
import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import TeamDetail from "./TeamDetail";


const routes = [
  { path: '/', component: <HomeOne />},
  { path: '/sanciones', component: <HomeTwo />},
  { path: '/actividades-pruebas', component: <HomeThree />},
  { path: '/404', component: <Page404 />},
  { path: '/galeria', component: <NftItemDetails />},
 
 


]

export default routes;