import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '../styles.scss';
import ProjectItem from '../project-item';

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const [datos, setDatos] = useState([]);

    useEffect(() => {
        const fetchData = () => {
            fetch("https://www.ucp.edu.co/sag/api/galeria")
                .then((res) => {
                    if (!res.ok) {
                        throw new Error("La solicitud falló");
                    }
                    return res.json();
                })
                .then((data) => setDatos(data))
                .catch((error) => console.log(error));
        };

        // Llama a fetchData una vez cuando el componente se monta
        fetchData();

        // Configura un intervalo para actualizar los datos cada 1 segundo
        const intervalId = setInterval(fetchData, 1000);

        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId);
    }, []);

    // Ahora puedes acceder al ID de la prueba en el componente
    const datab = datos.map((item) => ({
        id: item.id,
        img: item.url,
    }));

    const { data } = props;

    const [dataBlock] = useState({
       
    });

    const handleImageClick = (imageSrc) => {
        window.open(imageSrc, '_blank'); // Abre la URL de la imagen en una nueva ventana para descargar
    };

    return (
        <section className="tf-section tf-project nft-details pb-mobie">
            <div className="container-fluid">
                <Swiper
                    className='swiper-portfolio s1'
                    modules={[Autoplay]}
                    spaceBetween={30} // Espacio entre las imágenes (píxeles)
                    slidesPerView={2} // Cantidad de imágenes visibles a la vez
                    breakpoints={{
                        0: {
                            slidesPerView: 1, // Cambia a 1 imagen visible en pantallas pequeñas
                            spaceBetween: 10, // Reduce el espacio entre las imágenes en pantallas pequeñas
                        },
                        767: {
                            slidesPerView: 1, // Vuelve a 2 imágenes visibles en pantallas medianas
                            spaceBetween: 20, // Ajusta el espacio entre las imágenes en pantallas medianas
                        },
                        991: {
                            slidesPerView: 2, // Muestra 4 imágenes en pantallas más grandes
                            spaceBetween: 30, // Espacio entre las imágenes en pantallas más grandes
                        },
                    }}
                    loop
                    autoplay={{
                        delay: 3000, // Retrasa la transición a 3 segundos entre imágenes
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    speed={1000} // Ajusta la velocidad de transición a 1 segundo
                >
                    {datab.map((item) => (
                        <SwiperSlide key={item.id}>
                            <ProjectItem
                                item={item}
                                onImageClick={handleImageClick}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}

export default Project;
