import bgImg1 from '../images/background/Fondo_3.png';
import bgImg2 from '../images/background/bg-3.jpg';
import bgImg3 from '../images/background/bg-4.jpg';
import img1 from '../images/logo/personajes_1.png';
import logossag from '../images/logo/saglogos.png';

const dataSlider = [
    {
        id: 1,
        title: 'Semana de acción de gracias ',
        titlle: 'Resultados en linea',
        subtille : 'Universidad Católica de Pereira',
        logos: logossag, 
        img : img1
    },
]
export default dataSlider;