import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import BlogItem from "./blog-item";
import Button from "../../components/button";
import CryptoJS from "crypto-js";

Blog.propTypes = {
  data: PropTypes.array,
};

function Blog(props) {
  const [datos, setDatos] = useState([]);
  const [categorias, setCategorias] = useState({});

  useEffect(() => {
    const fetchData = () => {
      fetch("https://www.ucp.edu.co/sag/api/resultados")
        .then((res) => {
          if (!res.ok) {
            throw new Error("La solicitud falló");
          }
          return res.json();
        })
        .then((data) => {
          const equipos = data.equipos;

          // Ordena los equipos por la nueva variable puntaje_total
          equipos.sort(
            (a, b) =>
              b.puntaje_total_puntajes -
              b.puntaje_total_penalidades -
              (a.puntaje_total_puntajes - a.puntaje_total_penalidades)
          );

          // Asigna categorías numéricas basadas en el orden de puntaje
          const categorias = {};
          let categoriaActual = 1;

          equipos.forEach((equipo, index) => {
            if (
              index > 0 &&
              equipo.puntaje_total_puntajes -
                equipo.puntaje_total_penalidades !==
                equipos[index - 1].puntaje_total_puntajes -
                  equipos[index - 1].puntaje_total_penalidades
            ) {
              // Incrementa la categoría solo si la nueva variable puntaje_total es diferente al anterior
              categoriaActual++;
            }

            categorias[equipo.id] = categoriaActual;
          });

          // Actualiza el estado con los datos de los equipos y las categorías asignadas
          setDatos(equipos);
          setCategorias(categorias);
        })
        .catch((error) => console.log(error));
    };

    // Llama a fetchData una vez cuando el componente se monta
    fetchData();

    // Configura un intervalo para actualizar los datos cada 5 segundos
    const intervalId = setInterval(fetchData, 5000);

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);

  const datab = datos.map((i) => ({
    id: i.id,
    img: i.img,
    category: `Puesto ${categorias[i.id]}`, // Asigna la categoría numérica basada en el puntaje total
    pruebas: `Actividades / Pruebas: ${i.puntaje_total_puntajes}`,

    penalidades: `Sanciones: ${i.puntaje_total_penalidades}`,
    total: `PUNTAJE TOTAL:`,
    totall: `${i.puntaje_total_puntajes - i.puntaje_total_penalidades}`,
    time: i.nombre,
  }));

  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "BLOGS",
    title: "Resultados globales por equipos",
  });

  return (
    <section className="tf-section blog">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title mb-60 mt-60">
              <br />
              <br />
              <br />
              <h4 className="title">{dataBlock.title}</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div className="blog-grid">
              {datab.map((item) => (
                <div key={item.id} className="blog-item">
                  <BlogItem item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;
