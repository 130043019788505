import React from 'react';
import PageTitle from '../components/pagetitle';
import img from '../assets/images/common/img15.jpg';
import { Link } from 'react-router-dom';
import Project from '../features/project/nftdetails';

import dataProject from '../assets/fake-data/dataProject';

function NftItemDetails(props) {
    return (
        <div className='page-item-details'>
            <PageTitle title='Galería' />         
            <Project data={dataProject} />
           
        </div>
    );
}

export default NftItemDetails;