import React from "react";
import Slider from "../components/slider/home-v2";
import dataSlider from "../assets/fake-data/data-slider";

import FAQ from "../features/faq/home-v2";
import dataFaq from "../assets/fake-data/data-faq";

function HomeTwo(props) {
  return (
    <div className="home-2">
      {/*     {<Slider data={dataSlider} />} */}
      <FAQ data={dataFaq} />
    </div>
  );
}

export default HomeTwo;
