import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../styles.scss";
import AccordionItem from "../accordion-item";

FAQ.propTypes = {
  data: PropTypes.array,
};

function FAQ(props) {
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch("https://www.ucp.edu.co/sag/api/resultadospr")
        .then((res) => {
          if (!res.ok) {
            throw new Error("La solicitud falló");
          }
          return res.json();
        })
        .then((data) => setDatos(data.puntajesPorPrueba))
        .catch((error) => console.log(error));
    };

    // Llama a fetchData una vez cuando el componente se monta
    fetchData();

    // Configura un intervalo para actualizar los datos cada 1 segundo
    const intervalId = setInterval(fetchData, 1000);

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);

  // Ahora puedes acceder al ID de la prueba en el componente
  const datab = datos.map((item) => ({
    id: item.prueba.prueba.id,
    title: item.prueba.prueba.pregunta,
    content: (
      <table style={{ width: "100%", margin: "10px 0" }}>
        <thead>
          <tr>
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
              Equipo
            </th>
            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
              Puntaje
            </th>
          </tr>
        </thead>
        <tbody>
          {item.prueba.puntajes.map((puntaje) => (
            <tr key={puntaje.id}>
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                {puntaje.nombre}
              </td>
              <td style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                {puntaje.puntaje}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
  }));

  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "Puntajes",
    title: "Actividades/pruebas",
  });

  return (
    <section className="tf-section faq bg-st2">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <br />
            <div
              className="tf-title mb-58"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title" style={{ color: "##ED09E5FF" }}>
                {dataBlock.subtitle}
              </p>

              <h4 className="title">{dataBlock.title}</h4>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div
              className="flat-accordion"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {datab.slice(0, Math.ceil(datab.length / 2)).map((item) => (
                <AccordionItem key={item.id} item={item} />
              ))}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div
              className="flat-accordion"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {datab.slice(Math.ceil(datab.length / 2)).map((item) => (
                <AccordionItem key={item.id} item={item} />
              ))}
              -
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
