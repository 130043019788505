import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss'

BlogItem.propTypes = {
    item: PropTypes.object,
};

function BlogItem(props) {
    const {item} = props;

    return (
        <div className="post-item">
            <div className="wrap">
                <div className="tag">{item.category}</div>
                <div className="image">
                    <img src={item.img} alt="Crybox" />
                </div>
                <div className="content-item">
                    <div className="content">
                        <div className="meta-post">
                            <div><h6>{item.time}</h6></div>
                        </div>
                        <div className="h7 title"><Link to="/actividades-pruebas">{item.pruebas} </Link></div>
                        <div className="h7 title"><Link to="/sanciones">{item.penalidades}</Link></div>
                        <div className="h7 title">{item.total} <span >{item.totall}</span></div>
                        <p>{item.text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogItem;