const dataFaq = [
    {
        id: 1,
        title: 'Whatt are the NFTs?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How do i get NFTs?',
        content: 'Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.',
    },
 
   
   
 
]

export default dataFaq;